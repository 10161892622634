import { styled } from "@linaria/react";

import { family, fontSize } from "styles/fonts";
import { grey, standardColors } from "styles/colors";

const LegalEntityOverviewSection = styled.div`
  color: ${grey.dark};
  margin-bottom: 20px;
`;

const OverviewContainer = styled.div`
  display: flex;
`;

const OverviewSection = styled.div`
  background-color: ${standardColors.white};
  border-bottom: 1px solid ${grey.panel};
  border-right: 1px solid ${grey.panel};
  padding: 16px;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    border-right: 0;
    flex: 1;
  }
`;

const ScreeningRiskIcons = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 39px;
  padding-right: 39px;
  justify-content: space-between;
  width: 330px;
`;

const RiskIcons = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 39px;
  padding-right: 39px;
  justify-content: space-between;
  flex: 1;
`;

const ScreeningRiskHeader = styled.div``;

const RiskHeader = styled.div``;

const SectionTitle = styled.div`
  font-family: ${family.interSemiBold};
  font-size: ${fontSize.sm};
  color: ${grey.mid};
  margin: 0;
`;

const RiskIconsSectionTitle = styled(SectionTitle)`
  margin-bottom: 16px;
`;

const S = {
  LegalEntityOverviewSection,
  OverviewContainer,
  OverviewSection,
  RiskIcons,
  ScreeningRiskIcons,
  ScreeningRiskHeader,
  RiskHeader,
  RiskIconsSectionTitle
};

export default S;
