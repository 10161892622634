import React from "react";
import { Person } from "@mui/icons-material";
import Card from "components/molecules/Card";
import CardHeader from "components/molecules/CardHeader";
import { useParams } from "react-router-dom";
import Avatar from "components/atoms/Avatar";
import { AvatarSize } from "components/atoms/Avatar/types";
import { ReportPersona } from "api/reportPersonas/types";
import { CardVariant } from "components/molecules/Card/types";
import S, { classNameOverrides } from "./styles";

interface Props {
  item: ReportPersona;
}

const ReportPersonaCard = ({ item: persona }: Props) => {
  const params = useParams();
  const { enquiryId } = params;

  if (!persona) {
    return (
      <Card
        variant={CardVariant.default}
        className={classNameOverrides.failedCard}
      >
        <CardHeader
          variant={CardVariant.default}
          icon={
            <S.AvatarIconContainer>
              <Avatar
                avatarSize={AvatarSize.Large}
                hasBorder
                background={undefined}
              >
                <S.LoadingBar />
              </Avatar>
            </S.AvatarIconContainer>
          }
        />
      </Card>
    );
  }
  const personaReportURL = `/report/${enquiryId}/persona/${persona.id}`;

  const openPersonaReport = () => {
    window.open(personaReportURL);
  };

  const isConfirmed = persona.subjectScorePercentage === 100;
  const isRejected = persona.subjectScorePercentage === 0;

  const getClassName = (): string => {
    if (isConfirmed) {
      return classNameOverrides.card;
    }
    if (isRejected) {
      return classNameOverrides.failedCard;
    }
    return classNameOverrides.cardUpdating;
  };

  const formatSubjectScorePercentage = (
    subjectScorePercentage?: number
  ): string | null => {
    if (
      subjectScorePercentage !== undefined &&
      subjectScorePercentage !== null
    ) {
      return `Subject score: ${Math.floor(subjectScorePercentage)}%`;
    }
    return null;
  };

  return (
    <Card
      onClick={() => openPersonaReport()}
      variant={CardVariant.default}
      className={getClassName()}
    >
      <CardHeader
        variant={CardVariant.default}
        overview={persona?.title}
        icon={
          <S.AvatarIconContainer>
            <Avatar
              avatarSize={AvatarSize.Large}
              hasBorder
              background={undefined}
            >
              <Person fontSize="large" />
            </Avatar>
          </S.AvatarIconContainer>
        }
      />
      <S.Footer>
        <S.FooterContentWrapper variant={CardVariant.default}>
          <S.FooterContentLeft>
            {formatSubjectScorePercentage(persona.subjectScorePercentage)}
          </S.FooterContentLeft>
          <S.FooterContentLeft>
            {persona?.numberOfDres
              ? `Documents: ${persona.numberOfDres}`
              : null}
          </S.FooterContentLeft>
        </S.FooterContentWrapper>
      </S.Footer>
    </Card>
  );
};

export default ReportPersonaCard;
