import { styled } from "@linaria/react";
import { css } from "@linaria/core";

import { blue, grey } from "styles/colors";
import { fontSize } from "styles/fonts";
import { CardVariant } from "components/molecules/Card/types";

const LoadingBar = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: 104%;
  height: 104%;
  border-radius: 50%;
  border: 4px solid ${blue.xapienDark};
  border-left-color: ${blue.blueDiamond};
  animation: rotate 1.5s linear infinite;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const AvatarIconContainer = styled.div`
  position: relative;
  border-radius: 50%;
`;

const Footer = styled.footer`
  border-top: 1px solid ${grey.lightRule};
`;

const FooterContentWrapper = styled.div<{ variant: CardVariant }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75rem;
  color: ${({ variant }) =>
    variant === CardVariant.dark ? grey.rule : grey.mid};
  font-size: ${fontSize.xs};
`;

const FooterContentLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

/** classNameOverrides */

const card = css`
  cursor: pointer;

  &:hover {
    outline: 2px solid ${blue.xapienLight};
  }
`;

const cardUpdating = css`
  cursor: pointer;

  &:hover {
    outline: 2px solid rgba(40, 228, 250, 0.4);
  }
`;

const failedCard = css`
  & h5 {
    color: ${grey.ghost};
  }
`;

const S = {
  LoadingBar,
  AvatarIconContainer,
  Footer,
  FooterContentWrapper,
  FooterContentLeft
};

export const classNameOverrides = {
  card,
  cardUpdating,
  failedCard
};

export default S;
