import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";

import Report from "pages/report/Report";
import { ReportContextProvider } from "pages/report/ReportContextProvider";
import { ReportLoadingStatus } from "state/ReportStore";
import ModalContainer from "components/molecules/ModalContainer";
import useViewerMode from "util/hooks/useViewerMode";
import { useAuthentication } from "util/hooks/useAuthentication";
import { AuthenticationStatus } from "util/hooks/useAuthentication/types";
import { InsightReportProvider } from "util/hooks/useInsightReport";
import { RiskSummariesProvider } from "util/hooks/useRiskSummaries";
import type EnquiryStore from "state/EnquiryStore";
import type ReportStore from "state/ReportStore";
import type DefineStore from "state/DefineStore";
import type { UserAssessmentStore } from "state/UserAssessmentStore";

import S from "./styles";

interface Props {
  enquiryStore?: EnquiryStore;
  reportStore?: ReportStore;
  defineStore?: DefineStore;
  userAssessmentStore?: UserAssessmentStore;
}

const SharedReportContainer = ({
  enquiryStore,
  reportStore,
  defineStore,
  userAssessmentStore
}: Props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const isForPDFExport = searchParams.get("isForPDFExport") === "true";
  const params = useParams();
  const { setIsViewerModeEnabled } = useViewerMode();
  const {
    state: { status }
  } = useAuthentication();

  const navigate = useNavigate();

  const { enquiryId, personaId } = params;

  useEffect(() => {
    if (!enquiryStore || !reportStore) return;
    setIsViewerModeEnabled(true);
    enquiryStore.setCurrentEnquiryId(enquiryId);
    reportStore.fetch(enquiryId, token, isForPDFExport);
  }, [
    enquiryId,
    enquiryStore,
    reportStore,
    setIsViewerModeEnabled,
    token,
    isForPDFExport
  ]);

  if (!reportStore || !enquiryStore || !reportStore || !defineStore) {
    return null;
  }

  const { imageMap, loadingStatus, isReportRegenerationOpen } = reportStore;
  const report = { ...reportStore.report };
  const { nameOnlySearch } = defineStore;

  const reportProps = {
    report,
    loadingStatus,
    enquiryId,
    isReportRegenerationOpen,
    nameOnlySearch,
    enquiryStore,
    reportStore,
    userAssessmentStore
  };

  if (
    // TODO: Remove this casting once reportStore is typed
    (reportStore.loadingStatus as unknown as number) ===
    ReportLoadingStatus.shareTokenDeleted
  ) {
    console.warn("Share link not valid", {
      token,
      enquiryId,
      loadingStatus: reportStore.loadingStatus
    });
    return (
      <ModalContainer
        isOpen
        toggleOpen={() => {}}
        onExitClick={() => navigate("/login")}
      >
        <S.ModalHeader
          title={
            <S.ModalTitle>
              Xapien is a fully-automated research platform
            </S.ModalTitle>
          }
        />
        <S.ModalContent>
          <S.Subtitle>
            This Xapien report share link has been deleted
          </S.Subtitle>
          <div>You can ask the report&apos;s owner to share a new link.</div>
          <S.Link to="/login">
            {status !== AuthenticationStatus.authenticated
              ? "Sign in to Xapien"
              : "Close"}
          </S.Link>
        </S.ModalContent>
      </ModalContainer>
    );
  }
  return (
    <ReportContextProvider
      {...{ report, enquiryId, personaId, imageStore: imageMap }}
    >
      <InsightReportProvider>
        <RiskSummariesProvider>
          <Report {...reportProps} />
        </RiskSummariesProvider>
      </InsightReportProvider>
    </ReportContextProvider>
  );
};

export default inject(
  "reportStore",
  "userAssessmentStore",
  "enquiryStore",
  "defineStore"
)(observer(SharedReportContainer));
